/* spell-checker: disable */
const importCodeTemplate = `
    (async (guest, countries) => {
        const sleep = duration => new Promise(resolve => setTimeout(resolve, duration));

        const triggerTextEditChange = (selector, value) => {
            $(selector).val(value).focus();
            $(selector)[0].dispatchEvent(new InputEvent('input', { data: value }));
            $(selector)[0].dispatchEvent(new UIEvent('change'));
            $(selector)[0].dispatchEvent(new UIEvent('blur'));
        };

        const click = elementOrSelector => {
            let element;
            if (typeof elementOrSelector === 'string') {
                element = document.querySelector(elementOrSelector);
            } else {
                element = elementOrSelector;
            }
            element.dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: true, view: window }));
        };

        const selectDropdownItem = async (selector, value) => {
            $(selector + " label").click();
            await sleep(100);
            $($(".ui-dropdown-item")[value + 1]).click()
        };

        const monthMap = {
            'Január': 1,
            'Február': 2,
            'Március': 3,
            'Április': 4,
            'Május': 5,
            'Június': 6,
            'Július': 7,
            'Augusztus': 8,
            'Szeptember': 9,
            'Október': 10,
            'November': 11,
            'December': 12,
        };

        const setDatePickerValue = async (year, month, day) => {
            console.group('setDatePickerValue');
            console.log('setDatePickerValue', year, month, day);
            await sleep(100);
            if ($("select.ui-datepicker-year").length) {
                triggerTextEditChange(".ui-datepicker-year", year);
                triggerTextEditChange(".ui-datepicker-month", month - 1);
                await sleep(100);
            } else {
                console.log($(".ui-datepicker-year"));
                console.log($(".ui-datepicker-month"));
                let currentYear = +$(".ui-datepicker-year").text();
                console.log('currentYear', currentYear);
                while (currentYear !== year) {
                    if (currentYear < year) {
                        $('.ui-datepicker-next')[0].dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: true, defaultPrevented: true, view: window }));
                    } else {
                        $('.ui-datepicker-prev')[0].dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: true, defaultPrevented: true, view: window }));
                    }
                    currentYear = +$(".ui-datepicker-year").text();
                    console.log('currentYear', currentYear);
                }

                let currentMonth = monthMap[$(".ui-datepicker-month").text()];
                console.log('currentMonth', currentMonth);
                await sleep(100);

                while (currentMonth !== month) {
                    if (currentMonth < month) {
                        console.log('Next', $('.ui-datepicker-next')[0]);
                        $('.ui-datepicker-next')[0].dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: true, defaultPrevented: true, view: window }));
                    } else {
                        console.log('Prev', $('.ui-datepicker-next')[0]);
                        $('.ui-datepicker-prev')[0].dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: true, defaultPrevented: true, view: window }));
                    }
                    await sleep(100);
                    currentMonth = monthMap[$(".ui-datepicker-month").text()];
                    console.log('currentMonth', currentMonth);
                    await sleep(100);
                }
            }
            await sleep(100);
            const dayButton = $(".ui-datepicker-calendar a")
                .toArray().find(e => e.innerText.trim() === day.toString());
            if (!dayButton) {
                console.error('dayButton not found');
                console.log($(".ui-datepicker-calendar a")
                .toArray().map(e => e.innerText.trim()));
                debugger;
                throw new Error(\`Cannot find button: \${day}\`);
                /* await (new Promise(resolve => {
                    const handler = () => {
                        $(document).off('click', '.ui-datepicker-calendar a', handler);
                        resolve();
                    };
                    $(document).on('click', '.ui-datepicker-calendar a', handler);
                }));
                */
            } else {
                console.log('Click', dayButton);
                dayButton.dispatchEvent(new MouseEvent('click'));
            }
            await sleep(100);
            console.log('Success');
            console.groupEnd('setDatePickerValue');
        };
        const openDatePicker = (selector) => {
            $(selector + " .ui-datepicker-trigger").click();
        };
        const selectDate = async (selector, year, month, day) => {
            openDatePicker(selector);
            return setDatePickerValue(year, month, day);
        };

        triggerTextEditChange("#csaladiNev", guest.lastName);
        triggerTextEditChange("#keresztnev", guest.firstName);
        
        if (guest.sex === 'male') {
            click('input[type="radio"][value="FERFI"]')
        } else {
            click('input[type="radio"][value="NO"]')
        }

        triggerTextEditChange("#szuletesiHely", guest.birthPlace);

        openDatePicker("#szuletesiIdo");
        await setDatePickerValue(guest.birthDate.slice(0, 4), guest.birthDate
            .slice(5, 7)
            .replace(/^0?([^0].*)$/g, '$1'), guest.birthDate
                .slice(8)
                .replace(/^0?([^0].*)$/g, '$1'));
        await sleep(100);

        triggerTextEditChange("#okmanyazonosito", guest.idDocumentId);
        triggerTextEditChange("#iranyitoszam-input", guest.postcode);

        if (guest.idDocumentType === 'passport') {
            await selectDropdownItem("#okmanytipus", 1);
        }


        $("#vendegOrszag label").click();
        await sleep(100);

        $($("#vendegOrszag .ui-dropdown-item").toArray().filter(item => item.innerText === guest.countryNameHU)).click();
    })
`;
/* spell-checker: enable */

export default importCodeTemplate;
